<template>
  <div class="tabBar">
    <div class="item" @click="router.push('/myOrder')">
      <img src="@/assets/imgs/tabbar1.png" alt="">
    </div>
    <div class="item" @click="router.push('/')">
      <img src="@/assets/imgs/tabbar2.png" alt="">
    </div>
    <div class="item" @click="router.push('/mine')">
      <img src="@/assets/imgs/tabbar3.png" alt="">
    </div>
  </div>
  <div class="h57"></div>
</template>

<script setup>
  import { useRouter } from 'vue-router'

  const router = useRouter()
</script>

<style lang="less" scoped>
.tabBar{
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  border-top: 1px solid #fff;
  background: #000000;

  .item{
    width: calc(100% / 3);
    padding: 13px 0;

    img{
      width: 30px;
      margin: 0 auto;
    }
  }
}
.h57{
  height: 57px;
}
</style>
