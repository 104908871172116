<template>
  <div class="card">
    <van-nav-bar
        title="Card Activation"
        fixed
        placeholder
        left-arrow
        :border="false"
        @click-left="router.go(-1)"
    />
    <div class="main">
      <div class="add" @click="router.push('/card/add')">
        <img src="@/assets/imgs/icon_addB.png" alt="">
        <div class="text">Activate A New Card</div>
      </div>
      <div v-if="cardList.length" class="list">
        <!--激活成功-->
        <div
            v-for="(item, index) in cardList"
            :key="index"
            :class="{ activated: item.is_auth == 1, waiting: item.is_auth == 0, failed: item.is_auth == 2 }"
            class="item"
            @click="router.push('/card/detail?id=' + item.id)"
        >
          <div class="top">
            <div class="name">Meta Genesis Card</div>
            <div class="number">**** **** **** {{ item.bank_card_code.substring(item.bank_card_code.length - 4) }}</div>
          </div>
          <div class="bottom">
            <div class="name">
              <div class="label">Card Name</div>
              <div class="value">{{ item.u_name }}</div>
            </div>
            <div class="status">
              <div class="label">Card Status</div>
              <div v-if="item.is_auth == 1" class="value">Activated</div>
              <div v-if="item.is_auth == 2" class="value">Failed(Check your email)</div>
              <div v-if="item.is_auth == 0" class="value">Waiting for approval</div>
            </div>
          </div>
          <img v-if="item.is_auth == 1" class="board" src="@/assets/imgs/board1.png" alt="">
          <img v-if="item.is_auth == 2" class="board" src="@/assets/imgs/board2.png" alt="">
          <img v-if="item.is_auth == 0" class="board" src="@/assets/imgs/board3.png" alt="">
        </div>
      </div>
      <div v-else class="noData">No data available</div>
    </div>
  </div>

  <TabBar/>
</template>

<script setup>
  import TabBar from "@/components/TabBar";
  import { useRouter } from 'vue-router'
  import { getUserCardList } from '@/request/api'
  import { ref } from 'vue'

  const router = useRouter()
  const cardList = ref([])
  getUserCardList({
    address: sessionStorage.getItem('address')
  }).then(res => {
    if(res.success) {
      cardList.value = res.data
      console.log(cardList.value)
    }
  })
</script>

<style lang="less" scoped>
.card{
  .main{
    padding: 20px 32px;
    .add{
      background: #06C8A1;
      height: 52px;
      border-radius: 14px;
      display: flex;
      align-items: center;
      padding-left: 22px;
      margin-bottom: 25px;

      img{
        width: 22px;
        margin-right: 15px;
      }
      .text{
        font-size: 16px;
        color: #000000;
      }
    }
    .list{
      .item{
        width: 290px;
        height: 162px;
        margin: 0 auto 14px auto;
        display: flex;
        flex-direction: column;
        padding: 14px 0 12px 20px;
        color: #000000;
        justify-content: space-between;
        box-sizing: border-box;
        position: relative;

        .top{
          .name{
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 4px;
          }
          .number{
            font-size: 14px;
          }
        }
        .bottom{
          display: flex;
          align-items: center;

          .name{
            width: 96px;
            margin-right: 14px;
          }
          .label{
            color: #101010;
            font-size: 10px;
            margin-bottom: 6px;
            opacity: 0.8;
          }
          .value{
            font-size: 13px;
          }
        }
        .board{
          position: absolute;
          width: 35px;
          top: 50%;
          transform: translateY(-50%);
          right: 34px;
        }
      }
      .activated{
        background: url("~@/assets/imgs/card_bg1.png") no-repeat;
        background-size: 100% 100%;
      }
      .failed{
        background: url("~@/assets/imgs/card_bg2.png") no-repeat;
        background-size: 100% 100%;
      }
      .waiting{
        background: url("~@/assets/imgs/card_bg3.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .noData{
      text-align: center;
      font-size: 13px;
    }
  }
}
</style>
